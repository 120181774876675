<template>
    <div :class="getClassNames">
        <div
            class="top-bar"
            data-cy="top-bar"
        >
            <refresh :title="getGlobalSearchQuery ? getGlobalSearchQuery : projectDashboardTitle" />

            <div
                v-if="!getGlobalSearchQuery"
                class="top-bar-buttons"
            >
                <share-dropdown v-if="showExportAndShare" />

                <kw-button
                    v-if="isAddKeywordsButtonVisible"
                    id="add-keyword-button"
                    button-type="primary"
                    :button-text="$t('add-keywords-plus')"
                    :class="addKeywordsButtonClasses"
                    prepend-icon-class="kw kw-plus"
                    data-cy="add-keyword-button"
                    @click="goToAddKeywords"
                >
                    <div
                        v-if="getDraftsQuantity"
                        slot="appendSlot"
                    >
                        <span
                            class="add-keywords-quantity"
                            data-cy="add-keyword-draft-counter"
                            v-html="getDraftsQuantity || ''"
                        />
                    </div>
                </kw-button>
                <other-actions
                    v-if="isAdminOrMasterUser"
                    :config="getOtherActionsConfigs.otherActionsConfigs"
                    :show-notes="getOtherActionsConfigs.showNotes"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ViewkeyLinks from '@/mixins/ViewkeyLinks';
    import Refresh from 'components/refresh/Refresh';
    import OtherActions from 'components/common/OtherActions';
    import ShareDropdown from 'components/common/ShareDropdown';

    export default {
        name: 'TopBar',
        components: {
            'refresh': Refresh,
            'other-actions': OtherActions,
            'share-dropdown': ShareDropdown,
        },
        mixins: [ViewkeyLinks],
        props: {
            viewkeyRefreshedText: {
                type: String,
                required: false,
                default: null,
            },
            showActionButtons: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters([
                'getTrialPeriod',
                'getActiveTag',
                'getViewkeyView',
                'isViewOnlyUser',
                'getCurrentPlan',
                'getSelectedAuth',
                'getCurrentProject',
                'getDraftsQuantity',
                'getProjectProperty',
                'isAdminOrMasterUser',
                'getGlobalSearchQuery',
                'getListOfKeywordsDomains'
            ]),
            getClassNames() {
                return {
                    'top-bar__wrapper': true,
                    'with_top_banner': this.getTrialPeriod,
                };
            },
            isAddKeywordsButtonVisible () {
                return !this.getViewkeyView && !this.isViewOnlyUser;
            },
            projectDashboardTitle () {
                return this.getListOfKeywordsDomains.length > 1 || !this.getListOfKeywordsDomains.length
                    ? this.getProjectProperty('name')
                    : this.getListOfKeywordsDomains[0];
            },
            addKeywordsButtonClasses () {
                return {
                    'add-keywords': true,
                };
            },
            getOtherActionsConfigs () {
                const isGroup = this.getCurrentProject?.isSubproject
                const mainConfigs = [
                    {type: 'edit-project', icon: 'kw kw-edit', text: isGroup ? this.$t('edit-group') : this.$t('edit-project') , dataCy: 'menu-edit-project-button'},
                    {type: 'project-settings', icon: 'kw kw-link-2', text: isGroup ? this.$t('viewKey-group-settings') : this.$t('viewkey-project-settings'), dataCy: 'menu-project-settings-button'},
                    {type: 'divider'},
                    {type: 'subtitle', text: this.$t('remove')},
                    {type: 'archive', icon: 'kw kw-archive', text: isGroup ? this.$t('archive-group') :  this.$t('archive-project'), dataCy: 'menu-archive-button'},
                    {type: 'delete', icon: 'kw kw-trash-2', text: isGroup ? this.$t('delete-group') : this.$t('delete-project'), dataCy: 'menu-delete-button'},
                    {type: 'divider'},
                    {type: 'subtitle', text: this.$t('account-wide')},
                    {type: 'viewkey-settings', icon: 'kw kw-link-2', text: this.$t('viewkey-brand-settings'), dataCy: 'menu-viewkey-settings-button'},
                    {type: 'table-settings', icon: 'kw kw-table', text: this.$t('table-settings'), dataCy: 'menu-table-settings-button'},
                ]
                const withTagConfig = [
                    {type: 'edit-tag', icon: 'kw kw-edit-3', text: this.$t('edit-tag')},
                    {type: 'divider'},
                    {type: 'delete-tag', icon: 'kw kw-trash-2', text: this.$t('delete-tag')},
                ]
                const activeTag = this.getActiveTag.id;
                if(activeTag) {
                    return {
                        showNotes: false,
                        otherActionsConfigs: withTagConfig
                    }
                } else {
                    return {
                        showNotes: true,
                        otherActionsConfigs: mainConfigs
                    }
                }
            },
            showExportAndShare(){
                return !this.isViewOnlyUser && this.showActionButtons;
            }
        },
        methods: {
            goToAddKeywords () {
                if (this.getCurrentPlan.expiredStatus === 'expired') {
                    this.$toastr.w(this.$t('please-renew-subscription'));
                    return;
                }
                const initialTab = this.$route.query?.tab;
                this.$router.push({
                    name: 'addKeywords',
                    params: {id: this.getCurrentProject.id},
                    ...(initialTab ? {query: {tab: initialTab}} : {})
                });
            },
        },
    }
</script>

<style lang="scss">
  @import '~sass/partials/dropdowns';

  .top-bar__wrapper {
    background-color: kw-color(kw-white, 1);
    flex: 0 0 auto;
    padding: 25px 0 15px;
    position: sticky;
    top: 60px;
    width: 100%;
    z-index: 16;
    min-height: 81px;

    &.with_top_banner {
        top: 108px;
    }

    @media (max-width: 959px) {
        top: 49px;

        &.with_top_banner {
            top: 49px;
        }
    }
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    position: relative;

    .top-bar-buttons {
      align-items: flex-end;
      display: flex;
      justify-content: right;
    }

    .drop-menu {
      margin-top: 10px !important;
      top: 100%;
      width: 210px;
      z-index: 1;
    }
  }

  .add-keywords {
    margin-left: 8px;
    min-width: 125px;
    line-height: 20px;
    transition: all .25s ease;

    .kw-plus {
      font-size: 10px !important;
    }
  }

  @media screen and (max-width: 959px) {
    .top-bar__wrapper {
      padding: 15px 15px 10px 16px;
      top: calc(#{$header-height} - 8px);
    }
    .top-bar {
      flex-flow: column nowrap;
    }
    .top-bar-buttons {
      margin-top: 14px;
    }
  }
</style>
