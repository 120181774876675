<template>
    <main-block-with-overview v-if="showMainBlock" />
</template>

<script>
    import {mapGetters} from 'vuex';
    import MainBlockWithOverview from '@/components/main-block/MainBlockWithOverview.vue';
    
    export default {
        name: 'ProjectPage',
        components: {
            'main-block-with-overview': MainBlockWithOverview,
        },
        computed: {
            ...mapGetters([
                'getUserDataProp',
            ]),
            showMainBlock(){
                return !!this.getUserDataProp('email');
            },
        },
    }
</script>
