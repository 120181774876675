import client from '../client';
import interceptors from '../interceptors';

const resource = 'metrics';
const metricsInterceptors = interceptors.get('metrics');

client.interceptors.response.use(
    metricsInterceptors.s,
    metricsInterceptors.e,
);

const encode = id => encodeURIComponent(id);

export default {
    fetchKeywordSerpHistory: async (project, keyword, payload) =>
        await client.get(
            `/${resource}/${encode(project)}/competitors/${keyword}/history`,
            payload
        ),
    fetchKeywordSerpHistoryShared: async (project, keyword, payload) =>
        await client.get(
            `shared/${encode(project)}/${resource}/competitors/${keyword}/history`,
            payload
        ),
    fetchKeywordSerpHistorySharedWithHash: async (hash, keyword, payload) =>
        await client.get(
            `/projects/shared/link/${hash}/${resource}/competitors/${keyword}/history`,
            payload
        ),

    fetchChartsMetrics: async (payload = {}) => await client.get(`/${resource}`, payload),
    fetchMetricsTableData: async (params = {}) => await client.get(`/${resource}/projects-top-positions`, { params }),
    fetchViewkeyChartsMetrics: async (viewKey, payload) =>
        await client.get(`/shared/${viewKey}/${resource}`, payload),
    fetchMetrics: async (id, payload) =>
        await client.get(`/groups/${encode(id)}/${resource}`, payload),
    fetchBulkMetrics: async () => await client.get(`/${resource}/total`),
    fetchKeywordsCompetitorsMetrics: async (id, keywordId, payload) =>
        await client.get(`/${resource}/${encode(id)}/competitors/${keywordId}`, payload),
    fetchKeywordCompetitorSuggestions: async (id, keywordId, params = {}) =>
        await client.get(`/${resource}/${encode(id)}/competitors/${keywordId}/suggestions`, { params }),

    fetchAnalyticsChartData: async (id, payload) =>
        await client.get(`groups/${encode(id)}/${resource}/ga/category`, payload),
    fetchAnalyticsChartDataForShareViewKeys: async (viewKey, payload) =>
        await client.get(`shared/${viewKey}/${resource}/ga/category`, payload),
    fetchMindshareHistory: async (id, payload = {}, isFetchFromHistory = false) => {
        if (isFetchFromHistory) {
            return await client.get(`/${resource}/${encode(id)}/mindshare/history`, payload)
        } else {
            return await client.get(`projects/${id}/mindshare/from-cache`, payload)
        }
    },
    fetchViewkeyMindshareHistory: async (viewKey, payload = {}, isFetchFromHistory = false) => {
        if (isFetchFromHistory) {
            return await client.get(`shared/${resource}/${viewKey}/mindshare/history`, payload)
        } else {
            return await client.get(`shared/${resource}/${viewKey}/mindshare/from-cache`, payload)
        }
    },
    fetchMetricsRankings: async (id, payload = {}) =>
        await client.get(`/${resource}/${encode(id)}/rankings`, payload),
    fetchViewkeyMetricsRankings: async (viewKey, payload = {}) =>
        await client.get(`shared/${resource}/${viewKey}/rankings`, payload),
    fetchChartData: async (payload, params = {}) => await client.post(`/keywords/chart`, payload, { params }),
    fetchViewKeyChartData: async (viewKey, payload, params = {}) => await client.post(`/shared/${viewKey}/chart`, payload, { params }),
    fetchViewKeyChartDataWithHash: async (hash, payload, params = {}) => await client.post(`/projects/shared/link/${hash}/chart`, payload, { params }),

    fetchViewkeyChartsMetricsWithHash: async (hash, payload) => await client.get(`/projects/shared/link/${hash}/${resource}`, payload),
    fetchAnalyticsChartDataForShareViewKeysWithHash: async (hash) => await client.get(`/projects/shared/link/${hash}/${resource}/ga/category`),
    fetchKeywordCompetitorSuggestionsWithHash: async (hash, keywordId, params = {}) =>
        await client.get(`/projects/shared/link/${hash}/${resource}/competitors/${keywordId}/suggestions`, { params }),
    fetchViewkeyMindshareHistoryWithHash: async (hash, payload = {}, isFetchFromHistory = false) => {
        if (isFetchFromHistory) {
            return await client.get(`/projects/shared/link/${hash}/mindshare/history`, payload)
        } else {
            return await client.get(`/projects/shared/link/${hash}/mindshare/from-cache`, payload)
        }
    },
    fetchViewkeyMetricsRankingsWithHash: async (hash, payload = {}) =>
        await client.get(`/projects/shared/link/${hash}/rankings`, payload),
    fetchViewkeyKeywordsInTopPositions: async(viewKey, params) => await client.get(`/shared/${viewKey}/metrics/top_positions_history`, { params }),
    fetchViewkeyKeywordsInTopPositionsByTag: async(hash, params) => await client.get(`/projects/shared/link/${hash}/metrics/top_positions_history`, { params }),
    fetchVisibilityChartData: async (projectId, params) =>  await client.get(`/project/${projectId}/metrics/visibility`, { params }),
    fetchEstimatedTrafficChartData: async (projectId, params) =>  await client.get(`/project/${projectId}/metrics/estimated_traffic`, { params }),
    fetchKeywordsByIntentChartData: async (projectId, params) =>  await client.get(`/project/${projectId}/metrics/keywords_by_search_intents`, { params }),
    fetchSerpFeaturePerformanceChartData: async (projectId, params) =>  await client.get(`/project/${projectId}/metrics/serp_features`, { params }),
    fetchTopPagesChartData: async (projectId, params) =>  await client.get(`/project/${projectId}/metrics/top_pages`, { params }),
    fetchAverageRankChartData: async (projectId, params) =>  await client.get(`/project/${projectId}/metrics/ranking_averages`, { params }),
    fetchTopPositionsHistoryChartData: async (projectId, params) =>  await client.get(`/project/${projectId}/metrics/top_positions_history`, { params }),
};
